import '@/registerServiceWorker';

async function runApp() {
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    Vue.config.productionTip = false;

    const { GlobalRoamVueAuthentication } = await import(/* webpackChunkName: "gr-authentication" */ '@gr/authentication');
    Vue.use(await GlobalRoamVueAuthentication({ policies: ['GlobalRoam'], logOutput: console }));

    const { router, store, vuetify } = await import(/* webpackChunkName: "plugins" */ '@/plugins');
    const { default: AppComponent } = await import(/* webpackChunkName: "app.vue" */ '@/App.vue');

    const stores = await store();
    stores.commit('StorageStore/loadAll');

    new Vue({
        router: await router(),
        store: stores,
        vuetify: await vuetify(),
        render: h => h(AppComponent)
    }).$mount('#app');
}

runApp();

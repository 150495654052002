/* eslint-disable no-console */
import { ServiceWorkerStore } from '@/stores';
import { register } from 'register-service-worker';
import { BeforeInstallPromptEvent } from './types';

const notifyUserAboutUpdate = (worker: ServiceWorker | null) => {
    if (worker) {
        ServiceWorkerStore.mutations.setAsStale(ServiceWorkerStore.state, () => {
            worker.postMessage({ type: 'SKIP_WAITING' });
        });
    }
};

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready(registration) {
            console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
            // This checks for a new version of the pwa every 3 minutes.  Needed because app is a single page app and also a dashboard hence no navigation events occur.
            setInterval(() => registration.update(), 180000);
        },
        registered() {
            console.log('Service worker has been registered.');
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            console.log('New content is available; please refresh.');
            notifyUserAboutUpdate(registration.waiting);
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });

    let refreshing: boolean;
    navigator.serviceWorker.addEventListener('controllerchange', function () {
        if (refreshing) return;
        window.location.reload();
        refreshing = true;
    });

    let installPrompt!: BeforeInstallPromptEvent;
    window.addEventListener('beforeinstallprompt', beforeInstallPromptEvent => {
        beforeInstallPromptEvent.preventDefault();
        installPrompt = beforeInstallPromptEvent as BeforeInstallPromptEvent;
        ServiceWorkerStore.mutations.setAsInstallable(ServiceWorkerStore.state, async () => {
            installPrompt.prompt();
            const result = await installPrompt.userChoice;

            if (result.outcome === 'accepted') {
                // console.log('User accepted');
            } else {
                // console.log('User declined');
            }
        });
    });
}

import { IRootState } from './rootState';
import HeavyDogStore from './heavyDogStore';
import ServiceWorkerStore from './serviceWorkerStore';
import StorageStore from './storageStore';
import { ModuleTree } from 'vuex';

const stores = {
    HeavyDogStore,
    ServiceWorkerStore,
    StorageStore
} as ModuleTree<IRootState>;

export * from './rootState';
export * from './heavyDogStore';
export * from './storageStore';
export { stores, StorageStore, HeavyDogStore, ServiceWorkerStore };
export default stores;

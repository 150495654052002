import { RepoPipelineSummaries } from '@/repositories';
import { ActionContext, Module } from 'vuex';
import { IRootState } from '.';

// state
interface IHeavyDogState {
    lastRefresh: Date;
    repositorySummariesRefreshing: boolean;
    repositorySummaries: Array<RepoPipelineSummaries>;
}

// vuex module
class HeavyDogStore implements Module<IHeavyDogState, IRootState> {
    namespaced = true;

    // state
    state: IHeavyDogState = {
        lastRefresh: new Date(0),
        repositorySummariesRefreshing: true,
        repositorySummaries: []
    };

    // getters
    getters = {
        repoSummaries(s: IHeavyDogState): RepoPipelineSummaries[] {
            return s.repositorySummaries;
        },

        lastRefresh(s: IHeavyDogState): string {
            return s.lastRefresh.toLocaleString();
        },

        isRefreshing(s: IHeavyDogState): boolean {
            return s.repositorySummariesRefreshing;
        }
    };

    // mutations
    mutations = {
        updateDate(s: IHeavyDogState): void {
            s.lastRefresh = new Date();
        },

        repositorySummariesRefreshing(s: IHeavyDogState, value: boolean): void {
            s.repositorySummariesRefreshing = value;
        },

        updateRepositorySummaries(s: IHeavyDogState, repoSummaries: RepoPipelineSummaries[]): void {
            s.repositorySummaries = repoSummaries;
        }
    };

    actions = {
        async getData({ rootState, commit }: ActionContext<IHeavyDogState, IRootState>): Promise<void> {
            commit('repositorySummariesRefreshing', true);
            commit('updateRepositorySummaries', (await rootState.container.apiClient.getData()) ?? []);
            commit('updateDate');
            commit('repositorySummariesRefreshing', false);
        }
    };
}

export { IHeavyDogState, HeavyDogStore };

// export an instance of the store by default
export default new HeavyDogStore();

import Vue from 'vue';
import { Module } from 'vuex';
import { IRootState } from '.';

export enum Setting {
    filteringEnable = 'Enable Filtering',
    filterError = 'Filter Errors',
    filterWarnings = 'Filter Warnings',
    filterPRFails = 'Filter PR Failures',
    filterInProgress = 'Filter In Progress',
    filterCanceled = 'Filter Canceled',
    dontPromptToInstall = "Don't Prompt To Install"
}

export interface Settings {
    'Enable Filtering'?: boolean | undefined;
    'Filter Errors'?: boolean | undefined;
    'Filter Warnings'?: boolean | undefined;
    'Filter PR Failures'?: boolean | undefined;
    'Filter In Progress'?: boolean | undefined;
    'Filter Canceled'?: boolean | undefined;
    "Don't Prompt To Install"?: boolean | undefined;
}

let defaults: Settings = {
    'Enable Filtering': true
};

// state
interface IStorageState {
    settings: Settings;
}

// vuex module
class StorageStore implements Module<IStorageState, IRootState> {
    namespaced = true;

    // state
    state: IStorageState = {
        settings: {
            'Enable Filtering': undefined,
            'Filter Errors': undefined,
            'Filter Warnings': undefined,
            'Filter PR Failures': undefined,
            'Filter In Progress': undefined,
            'Filter Canceled': undefined,
            "Don't Prompt To Install": undefined
        }
    };

    // getters
    getters = {
        get: (s: IStorageState) => (key: Setting): boolean => {
            return Boolean(s.settings[key as keyof Settings]);
        }
    };

    // mutations
    mutations = {
        set(s: IStorageState, keyVal: { key: Setting; value: boolean }): void {
            localStorage.setItem(keyVal.key, keyVal.value.toString());
            if (localStorage.getItem(keyVal.key) === keyVal.value.toString()) Vue.set(s.settings, keyVal.key, keyVal.value);
        },

        loadAll(s: IStorageState): void {
            Object.values(Setting).forEach(key => {
                const value = localStorage.getItem(key) ?? defaults[key]?.toString();
                Vue.set(s.settings, key, value === 'true');
            });
        }
    };
}

export { IStorageState, StorageStore };

// export an instance of the store by default
export default new StorageStore();
